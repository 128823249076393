
// Libraries
import * as React from 'react'

// Components
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import RegisterForm from '../../../components/registerForm'

const RegisterOrgPage = () => (
	<Layout className="register-non-nhs nav-blue-half no-footer register-flow">
		<Seo title="Register Non-NHS Carer" />
		<RegisterForm process="non-nhs-carer"/>
	</Layout>
)

export default RegisterOrgPage
